<template>
    <modal class="updateLogModel" :isShow="isShow">
        <div class="header">更新日志</div>
        <div class="modal-body">
            <div class="steps-box">
                <div class="steps-year-li" :class="{selected:group.isMore}" v-for="group in logData" :key="group">
                    <div class="year-box">
                        <div class="step__head">
                            <div class="step__icon"></div>
                            <div class="step__line"></div>
                        </div>
                        <div class="step__main"  @click="group.isMore=!group.isMore" >
                            <div class="title">{{group.year}}</div>
                        </div>
                    </div>
                    <div class="more-box">
                        <div class="steps-li"  v-for="item in group.list" :key="item">
                            <div class="step__head">
                                <div class="step__icon"></div>
                                <div class="step__line"></div>
                            </div>
                            <div class="step__main">
                                <div class="title">{{item.year}}</div>
                                <div class="content-box">
                                    <p>1:界面重新设计：我们重新设计了工具栏，格式面板，状态栏以及其他界面，并且重构了所有的界面和交互来提升软件的整体体验；</p>
                                    <p>2:增强内核：我们重构了导图渲染引擎的内核，来提升渲染速度，编辑流畅度以及整体性能；</p>
                                    <p>3:深色界面：现在XMind: ZEN所有桌面端平台上都支持了深色界面。你可以在首选项中自由切换浅色和深色界面；</p>
                                    <p>4:大纲编辑视图：我们推出了一个全新的编辑方式——大纲，来帮助你更高效的整理并转换脑海中的想法成文字；</p>
                                    <p>5:新增功能——仅显示该分支，来帮助你集中精力在更重要的内容上；</p>
                                    <p>6:新增功能——主题链接，允许你从一个主题快速跳转到另外一个主题；</p>
                                    <p>7:新增功能——支持导入和导出OPML以及Textbundle格式的文件；</p>
                                    <p>8:快捷输入功能在 macOS 中可以用了，它能帮助你快速的记录你的想法并生成一张思维导图；</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>
export default {
    name:'updateLogModel',
    props:{
        isShow:Boolean
    },
    data(){
        return{
           isMore:false,
           logData:[{
               isMore:true,
                year:"2024",
                list:[{
                        year:"2024.3.11",
                        content:""
                },{
                        year:"2024.2.11",
                        content:""
                },{
                        year:"2024.1.11",
                        content:""
                }]
            },
            {
                year:"2023",
                list:[{
                        year:"2023.12.11",
                        content:""
                },{
                        year:"2023.10.11",
                        content:""
                },{
                        year:"2023.3.11",
                        content:""
                }]
            },
            {
                year:"2022",
                list:[{
                        year:"2022.12.11",
                        content:""
                }]
            }]
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.Loding()
        })
    },
    methods:{
        Loding(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
        },
        hide(){
            this.$emit("closeModel")
        },
        confirm(){
            this.$emit("closeModel")
        },
        updater() {
            const loading = this.$loading({
                text: "获取更新日志信息...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo();
            let param={
                User_ID:userInfo.User_ID, //登陆用户ID
                Sys_VersionID:this.$global.getSysVersion?.Version_ID
            }
            this.$httpAES.post("Bestech.CloudPos.CheckUpgrade",param).then((d)=>{
               
            }).catch((e)=>{
                loading.close();
               
            }) 
        },
    }
}
</script>

<style lang="scss">
@import './updateLogModel.scss';
</style>